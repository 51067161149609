<template>
    <div class="containerAffichage2">
        <bloc-professionnalisation  :sitePro=sitePro />
    </div>
</template>


<script>
import Professionnalisation from '@/components/pro/Professionnalisation.vue'


export default {
    name: 'Professionnalisation',

    components: {
        'bloc-professionnalisation': Professionnalisation,
    },
    props: {
        sitePro: {
            type: Boolean
        }
    },
    created() {
        if (this.sitePro) {
            this.$parent.sitePRO = true;
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">

#app {
    .containerAffichage2 {
        padding:1em 0;
    }
    .scrollup {
        display: none !important;
    }
}

</style>

